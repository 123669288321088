import { template as template_bb8f4a6c0c364c9cb652b31bde4efc48 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bb8f4a6c0c364c9cb652b31bde4efc48(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
