import { template as template_9c6bd1c2a7e042149cc0c025995cbdd7 } from "@ember/template-compiler";
const FKLabel = template_9c6bd1c2a7e042149cc0c025995cbdd7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
